import React, { useEffect, useState } from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import useAuthContext from "decentraland-gatsby/dist/context/Auth/useAuthContext"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import env from "decentraland-gatsby/dist/utils/env"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"
import { useTabletAndBelowMediaQuery } from "decentraland-ui/dist/components/Media/Media"

import Layout from "../../../../components/Layout/Layout"
import NotFound from "../../../../components/NotFound/NotFound"
import { useImageOptimization } from "../../../../hooks/contentful"
import useEventQuery from "../../../../hooks/useEventQuery"
import { FeatureFlag } from "../../../../modules/ff"
import Rewards from "../../../../modules/rewards"

import "./reward.css"
enum ClaimStatus {
  PENDING = "pending",
  ASSIGNED = "assigned",
  SENDING = "sending",
  SUCCESS = "success",
}

const RewardsAPI = Rewards.get()

export default function RewardPage(props: { reward: string; event: string }) {
  const event = useEventQuery(props.event)
  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()
  const isMobile = useTabletAndBelowMediaQuery()
  const successClaimBackgroundUrl = isMobile
    ? event?.claim_success_background_mobile.url
    : event?.claim_success_background.url
  const optimizedBackground = useImageOptimization(successClaimBackgroundUrl)
  const [claimStatus, setClaimStatus] = useState<ClaimStatus>(
    ClaimStatus.ASSIGNED
  )
  const l = useFormatMessage()
  const [account, { loading: loadingAccount }] = useAuthContext()

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await RewardsAPI.getReward(props.reward)
      setClaimStatus(response.data.status as ClaimStatus)
      if (response.data.status === ClaimStatus.SUCCESS) {
        clearInterval(interval)
      }
      return
    }, 5000)
    return () => clearInterval(interval)
  }, [props.reward])

  if (!featureFlagsLoaded || featureFlagsLoading) {
    return <Loader active size="large" />
  }

  if (
    !ff.flags[FeatureFlag.ConferencePage] ||
    !event ||
    (!account && !loadingAccount)
  ) {
    return <NotFound />
  }

  return (
    <Layout className="event-reward" {...props}>
      <Head title={event.title} />
      <div
        style={{
          backgroundImage: `url(${optimizedBackground.optimized})`,
        }}
        className="event-reward__success"
      >
        <div className="event-reward__success-info-container">
          <div className="event-reward__success-info">
            <h2 className="event-reward__success-title">
              {l("page.claim_wearable.success.title")}
            </h2>
            <h5 className="event-reward__success-subtitle">
              {l("page.claim_wearable.success.subtitle", {
                event: event.title,
              })}
            </h5>
            <div className="event-reward__claim-status">
              <div className="event-reward__claim-status-steps">
                <span
                  className={TokenList.join([
                    "event-reward__circle-container event-reward__circle-container--active",
                    claimStatus === ClaimStatus.ASSIGNED &&
                      "event-reward__circle-container--loading",
                  ])}
                >
                  <span className="event-reward__circle" />
                </span>
                <span
                  className={TokenList.join([
                    "event-reward__circle-container",
                    [
                      ClaimStatus.PENDING,
                      ClaimStatus.SENDING,
                      ClaimStatus.SUCCESS,
                    ].includes(claimStatus) &&
                      "event-reward__circle-container--active",
                    claimStatus === ClaimStatus.ASSIGNED &&
                      "event-reward__circle-container--loading",
                  ])}
                >
                  <span className="event-reward__circle" />
                </span>
                <span
                  className={TokenList.join([
                    "event-reward__circle-container",
                    [ClaimStatus.SENDING, ClaimStatus.SUCCESS].includes(
                      claimStatus
                    ) && "event-reward__circle-container--active",
                    claimStatus === ClaimStatus.PENDING &&
                      "event-reward__circle-container--loading",
                  ])}
                >
                  <span className="event-reward__circle" />
                </span>
                <span
                  className={TokenList.join([
                    "event-reward__circle-container",
                    [ClaimStatus.SUCCESS].includes(claimStatus) &&
                      "event-reward__circle-container--active",
                    claimStatus === ClaimStatus.SENDING &&
                      "event-reward__circle-container--loading",
                  ])}
                >
                  <span className="event-reward__circle" />
                </span>
              </div>
              <div className="event-reward__claim-status-labels">
                <span>{l("page.claim_wearable.status.assigned")}</span>
                <span>{l("page.claim_wearable.status.pending")}</span>
                <span>{l("page.claim_wearable.status.sending")}</span>
                <span>{l("page.claim_wearable.status.success")}</span>
              </div>
            </div>
            <div className="event-reward__success-actions">
              <Button primary href={env("EXPLORER_URL")} target="_blank">
                {l("page.claim_wearable.success.jump_in")}
              </Button>
              <Button
                secondary
                inverted
                target="_blank"
                href={`${env("PROFILE_DAPP_URL")}/accounts/${account}/assets`}
              >
                {l("page.claim_wearable.success.profile")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

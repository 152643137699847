import API from "decentraland-gatsby/dist/utils/api/API"
import env from "decentraland-gatsby/dist/utils/env"

export type Captcha = {
  id: string
  width: number
  height: number
  image: string
}
type Reward = {
  id: string
  image: string
  status: "assigned" | "pending" | "sending" | "success" | "failure"
}

export default class Rewards extends API {
  static URL = env("REWARDS_API", "https://rewards.decentraland.org/api")
  static CACHE = new Map<string, Rewards>()

  static from(url: string) {
    if (!this.CACHE.has(url)) {
      this.CACHE.set(url, new Rewards(url))
    }

    return this.CACHE.get(url)!
  }

  static get() {
    return this.from(env("REWARDS_API", this.URL))
  }

  async getCaptcha(): Promise<Captcha> {
    const response = await this.fetch<{ data: Captcha; ok: boolean }>(
      "/captcha",
      this.options({ method: "POST" })
    )
    if (!response.ok) {
      throw new Error("Couldn't fetch captcha")
    }
    return response.data
  }

  async claimWearable({
    address,
    dispenserKey,
  }: {
    address: string
    dispenserKey: string
  }) {
    return this.fetch<{ ok: boolean; data: Reward[] }>(
      "/rewards",
      this.options({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaign_key: dispenserKey,
          beneficiary: address,
        }),
      }).authorization({
        sign: true,
      })
    )
  }

  async getReward(id: string) {
    return this.fetch<{ ok: boolean; data: Reward }>(
      `/rewards/${id}`,
      this.options({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    )
  }
}
